import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Constrain from "../../../components/constrain"
import Image from "../../../components/image"
import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheVerliebt = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheVerliebtIndexQuery {
      bueste: file(
        relativePath: { eq: "leichte-sprache/bueste-ausstellung.jpg" }
      ) {
        ...largeImage
      }
      matheheft: file(
        relativePath: { eq: "leichte-sprache/matheheft-henry-gontard.png" }
      ) {
        ...largeImage
      }
      brief: file(
        relativePath: { eq: "leichte-sprache/brief-susette-gontard.png" }
      ) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="leichte-sprache-verliebt">
      <Seo
        title="Hölderlin verliebt sich"
        description="Als Haus∙lehrer arbeitet Hölderlin bei verschiedenen Familien. Zum Beispiel bei der Familie Gontard in Frankfurt. In der Familie Gontard gibt es 4 Kinder. Hölderlin verliebt sich in die Mutter der Kinder. Sie heißt Susette Gontard. Susette verliebt sich auch in Hölderlin. Sie haben heimlich eine Beziehung."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Verliebt",
                link: "/leichte-sprache/ueber-hoelderlin/verliebt",
              },
            ]}
          />
          <PageTitle>Hölderlin verliebt sich</PageTitle>
          <Paragraph dropcap={true}>
            Hölderlin will nicht Pfarrer sein. <br />
            Aber er muss Geld verdienen. <br />
            Deshalb arbeitet er als Haus∙lehrer. <br />
            Ein Haus∙lehrer unterrichtet nicht in einer Schule. <br />
            Er kommt zu den Kindern nach Hause. <br />
            Manchmal wohnt ein Haus∙lehrer auch bei der Familie, <br />
            bei der er arbeitet. <br />
            Er bringt den Kindern alles bei, <br />
            was andere Kinder in der Schule lernen.
          </Paragraph>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/06-verliebt" />
          </Constrain>
        </Stack>

        <Constrain>
          <Stack>
            <Paragraph>
              Als Haus∙lehrer arbeitet Hölderlin bei verschiedenen Familien.{" "}
              <br />
              Zum Beispiel bei der Familie Gontard in Frankfurt. <br />
              (Den Namen spricht man so aus: Gontaar.) <br />
              In der Familie Gontard gibt es 4 Kinder.
            </Paragraph>
            <Image
              image={data.matheheft.childImageSharp.gatsbyImageData}
              caption="Ein Schul∙heft von Hölderlins Schüler Henry Gontard. Aus der Württembergischen Landesbibliothek Stuttgart."
              alt="Ein Heft mit vielen Notizen. Die linke Seite ist mit Text vollgeschrieben. Auf der rechten Seite stehen mathematische Formeln."
            />
          </Stack>
        </Constrain>

        <Columns collapse={[true, false]} space={12} alignY="center">
          <Column>
            <Paragraph>
              Hölderlin verliebt sich in die Mutter der Kinder. <br />
              Sie heißt Susette Gontard. <br />
              Susette verliebt sich auch in Hölderlin. <br />
              Sie haben heimlich eine Beziehung. <br />
              Aber Herr Gontard findet das heraus. <br />
              Deshalb darf Hölderlin dort nicht mehr arbeiten.
            </Paragraph>
          </Column>
          <Column width={["full", "2/5"]}>
            <Image
              image={data.bueste.childImageSharp.gatsbyImageData}
              caption="Susette Gontard"
              attribution="David Franck"
              alt="Eine kleine weiße Figur von einer Frau. Die feinen Lockenhaare sind zu einem Zopf hochgesteckt."
            />
          </Column>
        </Columns>

        <Columns
          collapse={[true, false]}
          space={12}
          alignY="center"
          reverse={true}
        >
          <Column>
            <Stack>
              <Paragraph hyphens="no">
                Hölderlin trifft Susette aber noch manchmal heimlich. <br />
                Und sie schreiben sich heimlich Briefe.
              </Paragraph>
            </Stack>
          </Column>
          <Column width={["full", "2/5"]}>
            <Image
              image={data.brief.childImageSharp.gatsbyImageData}
              attribution="WLB Stuttgart"
              caption="Ein Liebes∙brief von Susette Gontard an Hölderlin."
              alt="Der Brief zeigt die schöne, geschwungene Handschrift von Susette Gontard."
            />
          </Column>
        </Columns>

        <Paragraph>
          Später erfährt Hölderlin: <br />
          Susette ist gestorben. <br />
          Das macht ihn sehr traurig. <br />
          Danach arbeitet Hölderlin nicht mehr als Haus∙lehrer. <br />
          Er wohnt wieder bei seiner Mutter in Nürtingen. <br />
          Dann geht er nach Bad Homburg. <br />
          Bad Homburg ist eine Stadt in Hessen. <br />
          Dort wohnt er bei seinem Freund Isaac von Sinclair.
        </Paragraph>
        <LeichteSpracheNext variant="bibliothekar" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheVerliebt
